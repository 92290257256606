import React from 'react';
import FeaturedInfo from '../components/FeaturedInfo';

const players2 = [
  { id: 1, week: '2', game: 'Saints @ Cowboys', bet: 'Over 46.5', result: 'N/A' },
  { id: 2, week: '2', game: 'Vikings @ 49ers', bet: 'Over 45.5', result: 'N/A' },
  { id: 3, week: '2', game: 'Steelers @ Broncos', bet: 'Steelers -2.5', result: 'N/A' },
];

const players = [
  { id: 1, week: '1', game: 'Ravens @ Chiefs', bet: 'Under 46.5', result: 'Loss' },
  { id: 2, week: '1', game: 'Jaguras @ Dolphins', bet: 'Dolphins -3.5', result: 'Loss' },
  { id: 3, week: '1', game: 'Raiders @ Chargers', bet: 'Over 40', result: 'Loss' },
  { id: 4, week: '1', game: 'Cowboys @ Browns', bet: 'Cowboys -2 && Over 41', result: 'Win' },
];

const NFLStatsTable = () => (
  <>
  <div class="item3">
    <FeaturedInfo />
  </div>
  <br /><br />

  <div class="table-container">
    <table class="styled-table">
      <thead>
        <tr>
          <th>Week</th>
          <th>Game</th>
          <th>Bet</th>
          <th>Result</th>
        </tr>
      </thead>
      <tbody>
        {players2.map(player2 => (
          <tr key={player2.id}>
            <td>{player2.week}</td>
            <td>{player2.game}</td>
            <td>{player2.bet}</td>
            <td class="td-result">{player2.result}</td>
          </tr>
        ))}
      </tbody>
    </table>
  </div>
<br /><br />
  <div class="table-container">
    <table class="styled-table">
      <thead>
        <tr>
          <th>Week</th>
          <th>Game</th>
          <th>Bet</th>
          <th>Result</th>
        </tr>
      </thead>
      <tbody>
        {players.map(player => (
          <tr key={player.id}>
            <td>{player.week}</td>
            <td>{player.game}</td>
            <td>{player.bet}</td>
            <td class="td-result">{player.result}</td>
          </tr>
        ))}
      </tbody>
    </table>
    <br /><br />
  </div>
  </>
);

export default NFLStatsTable;
