function FeaturedInfo() { 
  return ( 
    <div className="featured"> 
      <div className="featuredItem"> 
        <span className="featuredTitle">Last Week</span> 
        <div className="featuredMoneyContainer"> 
          <span className="featuredMoney">1 - 3</span> 
          {/* <span className="featuredMoneyRate"> 
            -11.4
          </span>  */}
        </div> 
        {/* <span className="featuredSub"> Compared to last month</span>  */}
      </div> 
        <div className="featuredItem"> 
            <span className="featuredTitle">Season</span> 
            <div className="featuredMoneyContainer"> 
            <span className="featuredMoney">1 - 3</span> 
            {/* <span className="featuredMoneyRate"> 
                +2.4 
            </span>  */}
            </div> 
            {/* <span className="featuredSub"> Compared to last month</span>  */}
        </div> 
        </div> 
    ); 
} 
export default FeaturedInfo;
